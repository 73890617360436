import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import VideoPlayer from '~components/VideoPlayer'

const LightBox = ({ className, video, show, onClose }) => {

	const close = () => {
		onClose()	
	}

	return (
		<>
			<Wrap className={className} show={show}>
				<Container>
					<Close className='xs' onClick={() => close()}>
					Close
					</Close>
					{video && 
						<StyledVideoPlayer 
							desktopVideo={video?.desktopVideo}
							mobileVideo={video?.mobileVideo ?? video?.desktopVideo}
							soundToggle={video?.showMuteToggle}
						/>
					}
				</Container>
				<Overlay onClick={() => close()}/>
			</Wrap>
		</>
	)
}

const Wrap = styled.button`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0 71px;
	pointer-events: ${ props => props.show ? 'all' : 'none'};
	opacity: ${ props => props.show ? '1' : '0'};
	transition: opacity 0.4s;
	display: flex;
	align-items: center;
	z-index: 100;
	${mobile}{
		padding: 0  20px;
	}
`
const Container = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	max-width: calc((100vh * 1.77778) - 400px);
	margin: 0 auto;
`
const StyledVideoPlayer = styled(VideoPlayer)`
	width: 100%;
`
const Overlay = styled.div`
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	z-index: -1;
	background-color: var(--white);
`
const Close = styled.button`
	background-color: var(--white);
	border: 1px solid var(--black);
	font-family: inherit;
	min-height: 26px;
	padding: 5px 8px;
	box-sizing: border-box;
	border-radius: 20px;
	transition: background-color 0.25s, color 0.25s;
	display: flex;
	align-items: center;
	margin-bottom: 16px;
	:hover{
		background-color: var(--black);
		color: var(--white);
	}
`

LightBox.propTypes = {
	className: PropTypes.string,
	video: PropTypes.object,
	show: PropTypes.bool,
	onClose: PropTypes.func,
}

export default LightBox