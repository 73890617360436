import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import RichText from '~components/RichText'
import canUseDOM from '~utils/canUseDOM'
import '~styles/flickity.css'
import { CircleArrowLeft, CircleArrowRight } from '~components/Svg'

const AboutSliderMobile = ({ className, slides }) => {

	const [init, setInit] = useState()
	const [selectedIndex, setSelectedIndex] = useState(0)

	const flkty = useRef()
	const flickityEl = useRef()

	useEffect(() => {
		if (canUseDOM && init){
			const Flickity = require('flickity')
			flkty.current = new Flickity(flickityEl.current, flickityOptions)
		}
		setInit(true)
	}, [init])

	const nextSlide = () => {
		flkty?.current?.next()
		setSelectedIndex(flkty?.current?.selectedIndex)
	}

	const prevSlide = () => {
		flkty?.current?.previous()
		setSelectedIndex(flkty?.current?.selectedIndex)
	}

	useEffect(() => {
		if (init) {
			flkty?.current?.on('dragEnd', () => {
				setSelectedIndex(flkty?.current?.selectedIndex)
			} )
		}
	}, [init])

	const flickityOptions = {
		prevNextButtons: false,
		pageDots: false,
		adaptiveHeight: false,
		wrapAround: false,
		cellAlign: 'left',
		freeScroll: false,
		contain: true,
		draggable: true,
	}

	return (
		<>
			<Wrap className={className}>
				<Flickity ref={flickityEl}>
					{slides.map(s => (
						<Slide key={s.asset?._id} >
							<Image image={s} aspectRatio={0.799}/>
							<Caption 
								className='caption'
							>
								<RichText content={s.caption}/>
							</Caption>
						</Slide>
					))}
				</Flickity>
				<ButtonsContainerMobile>
					<ImageHeight>
						<Buttons>
							<Prev 
								onClick={ ()=> prevSlide()}
								disabled={selectedIndex === 0}
							>
								<CircleArrowLeft />
							</Prev>
							<Next 
								onClick={ ()=> nextSlide()}
								disabled={selectedIndex === slides.length - 1}
							>
								<CircleArrowRight />
							</Next>
						</Buttons>
					</ImageHeight>
				</ButtonsContainerMobile>	
			</Wrap>
			<ButtonsContainerDesktop>
				<ButtonsPosition>
					<Buttons>
						<Prev 
							onClick={ ()=> prevSlide()}
							disabled={selectedIndex === 0}
						>
							<CircleArrowLeft />
						</Prev>
						<Next 
							onClick={ ()=> nextSlide()}
							disabled={selectedIndex === slides.length - 1}
						>
							<CircleArrowRight />
						</Next>
					</Buttons>
				</ButtonsPosition>
			</ButtonsContainerDesktop>	
		</>
	)
}

const Wrap = styled.div`
	position: relative;
`
const Flickity = styled.div`
	.flickity-viewport{
		overflow: visible;
		${mobile}{
			overflow: hidden;
		}
	}
`
const Slide = styled.div`
	width: 100%;
	margin-right: 30px;
`
const Caption = styled.div`
	margin-top: 14px;
	${mobile}{
		margin-top: 12px;
		padding: 0 20px; 
	}
`

const ButtonsContainerMobile = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	left: 0;
	bottom: 0;
	pointer-events: none;
	z-index: 1;
	display: none;
	${mobile}{
		display: block;
	}
`
const ButtonsContainerDesktop = styled.div`
	grid-column: span 12;
	height: 0px;
	position: relative;
	${mobile}{
		display: none;
	}
`
const ButtonsPosition = styled.div`
	
`
const ImageHeight = styled.div`
	position: relative;
	height: 0;
	padding-bottom: 125.2%;		
`
const Buttons = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	position: absolute;
	bottom: 70px;
	right: 0;
	left: 0;
	padding-bottom: 0px;
	color: var(--white);
	${mobile}{
		top: 0;
		bottom: 0;
		padding: 0 20px 16px;
		color: var(--black);
	}
`
const Prev = styled.button`
	width: 33px;
	pointer-events: ${ props => props.disabled ? 'none' : 'all'};
	opacity: ${ props => props.disabled ? '0' : '1'};
	transition: opacity 0.25s;

`
const Next = styled.button`
	width: 33px;
	pointer-events: all;
	opacity: ${ props => props.disabled ? '0' : '1'};

`

AboutSliderMobile.propTypes = {
	className: PropTypes.string,
	slides: PropTypes.array,
}

export default AboutSliderMobile