import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet, DiagonalArrowLarge } from '~styles/global'

const DiagonalArrowButton = ({ className, s, onChange }) => {
	
	const [hover, setHover] = useState(false)

	console.log(s)

	return (
		<Hover 
			onMouseEnter={() => setHover(true)}
			onMouseLeave={() => setHover(false)}
		>
			<Container 
				onClick={() => onChange(s.reelVimeo)}
			>
				<DiagonalArrowLarge/>
				<Underline hover={hover}>{s.title}</Underline>
			</Container>
		</Hover>
	)
}

const Hover = styled.div`
	
`
const Container = styled.button`
	display: flex;
	align-items: center;
	margin-bottom: 4px;
	:last-of-type{
		margin-bottom: 0;
	}
`
const Underline = styled.div`
	text-decoration: ${ props => props.hover ? 'underline' : 'none'};
`

DiagonalArrowButton.propTypes = {
	className: PropTypes.string,
	s: PropTypes.object,
	onChange: PropTypes.func,
}

export default DiagonalArrowButton



