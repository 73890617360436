import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { css } from '@emotion/react'
import { mobile, tablet } from '~styles/global'
import Image from '~components/Image'
import RichText from '~components/RichText'
import canUseDOM from '~utils/canUseDOM'
import '~styles/flickity.css'
import { CircleArrowLeft, CircleArrowRight } from '~components/Svg'
import useEmblaCarousel from 'embla-carousel-react'

const AboutSlider = ({ className, slides }) => {

	const [init, setInit] = useState()

	const [emblaRef, emblaApi] = useEmblaCarousel({
		containScroll: 'trimSnaps',
		dragFree: true,
		autoplay: false,
		loop: false,
	})
	const [selectedIndex, setSelectedIndex] = useState(0)
	const prevSlideIndexRef = useRef(0)
	
	useEffect(() => {
		if (emblaApi) {
			const onSlideChange = () => {
				const currentIndex = emblaApi.selectedScrollSnap()
				if (currentIndex !== prevSlideIndexRef.current) {
					setSelectedIndex(currentIndex)
					prevSlideIndexRef.current = currentIndex
				}
			}
			emblaApi.on('select', onSlideChange)
			onSlideChange()
			return () => emblaApi.off('select', onSlideChange)
		}
	}, [emblaApi])


	const nextSlide = () => {
		emblaApi.scrollNext()
	}

	const prevSlide = () => {
		emblaApi.scrollPrev()
	}

	return (
		<>
			<Wrap className={className}>
				<Slider ref={emblaRef}>
					<SliderContainer>
						{slides.map(s => (
							<Slide key={s.asset?._id}  
								aspectRatio={s?.asset?.metadata?.dimensions?.aspectRatio}
							>
								<ImageDesk image={s} />
								<Caption 
									className='caption'
								>
									<RichText content={s.caption}/>
								</Caption>
							</Slide>
						))}
					</SliderContainer>
				</Slider>
			</Wrap>
			<ButtonsContainer>
				<ButtonsPosition>
					<Buttons>
						<Prev 
							onClick={ ()=> prevSlide()}
							disabled={selectedIndex === 0}
						>
							<CircleArrowLeft />
						</Prev>
						<Next 
							onClick={ ()=> nextSlide()}
							disabled={selectedIndex === slides.length - 1}
						>
							<CircleArrowRight />
						</Next>
					</Buttons>
				</ButtonsPosition>
			</ButtonsContainer>	
		</>
	)
}

const Wrap = styled.div`
	position: relative;
`
const Slider = styled.div`
	overflow: visible;
	height: 100%;
	cursor: grab;
	:active{
		cursor: grabbing;
	}
`
const SliderContainer = styled.div`
	display: flex;
	height: 100%;
`
const Slide = styled.div`
	margin-right: 30px;
	/* width: ${props => 504 * props.aspectRatio}px; */
	height: 504px;
	height: 100%;
	flex: 0 0 ${props => 504 * props.aspectRatio}px;
	:last-of-type{
		margin-right: 0;
	}
`
const Caption = styled.div`
	margin-top: 14px;
`
const ImageDesk = styled(Image)`
	display: block;
`
const ButtonsContainer = styled.div`
	grid-column: span 12;
	height: 0px;
	position: relative;
`
const ButtonsPosition = styled.div`
	
`
const Buttons = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	position: absolute;
	bottom: 70px;
	right: 0;
	left: 0;
	padding-bottom: 0px;
	color: var(--black);
`
const Prev = styled.button`
	width: 33px;
	pointer-events: ${ props => props.disabled ? 'none' : 'all'};
	opacity: ${ props => props.disabled ? '0' : '1'};
	transition: opacity 0.25s;

`
const Next = styled.button`
	width: 33px;
	pointer-events: all;
	opacity: ${ props => props.disabled ? '0' : '1'};
	pointer-events: ${ props => props.disabled ? 'none' : 'all'};

`

AboutSlider.propTypes = {
	className: PropTypes.string,
	slides: PropTypes.array,
}

export default AboutSlider